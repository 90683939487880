<template>
    <div class="header-container contact">
        <div class="container h-100">
            <div class="row header-wrapper">
                <div class="col-xl-6 left-col">
                    <h1 class="heading-section-text text-white mb-4"
                        data-aos="fade-up"
                        data-aos-duration="700"
                        data-aos-once="true"
                        data-aos-easing="ease-out-quart">
                        {{ this.trans("PAGES.CONTACT.HEADER.TITLE") }}
                    </h1>
                    <p class="heading-section-desc text-white"
                       data-aos="fade-up"
                       data-aos-duration="700"
                       data-aos-delay="500"
                       data-aos-once="true"
                       data-aos-easing="ease-out-quart">
                        {{ this.trans("PAGES.CONTACT.HEADER.DESCRIPTION") }}
                    </p>
                    <div class="form-content mt-5">

                        <form id="contact-form" class="text-center" method="POST" @submit.prevent="postNow">
                            <input type="text" v-model="form.name" name="name"
                                   :placeholder="`${this.trans('PAGES.CONTACT.CONTACT_FORM.NAME')} *`"
                                   class="form-input-field w-100" autocomplete="on" required>
                            <input type="email" v-model="form.email" name="email"
                                   :placeholder="`${this.trans('PAGES.CONTACT.CONTACT_FORM.EMAIL')} *`"
                                   class="form-input-field w-100"
                                   required>
                            <input type="tel" v-model="form.phone" name="phone"
                                   :placeholder="this.trans('PAGES.CONTACT.CONTACT_FORM.PHONE_NUMBER')"
                                   class="form-input-field w-100">
                            <textarea v-model="form.message" name="message"
                                      :placeholder="`${this.trans('PAGES.CONTACT.CONTACT_FORM.MESSAGE')} *`"
                                      class="form-input-field w-100" required></textarea>
                            <p style="font-size: 14px; text-align: left">{{ this.trans('PAGES.CONTACT.CONTACT_FORM.REQUIRED_FIELDS') + ' *'}}</p>

                            <button class="link-button mt-4" type="submit">
                                <div class="color-fill">
                                    <img class="arrow-icon" src="../assets/icons/arrow.svg" alt="">
                                </div>
                                <p class="link-text">{{ this.trans("BUTTONS.SEND_MESSAGE") }}</p>
                            </button>
                            <p id="form-status-message"></p>
                        </form>
                    </div>
                </div>
                <div class="col-xl-5 offset-lg-1 right-col">
                    <img src="../assets/icons/thunder.svg" alt="" class="thunder-icon d-xl-block d-none mb-5">
                    <div class="row">
                        <div class="col-sm-6 text-white mb-3">
                            <h6 class="box-heading-text">{{
                                    this.trans("PAGES.CONTACT.CONTACT_INFORMATION.ADDRESS")
                                }}</h6>
                            <p class="box-desc-text text-white"></p>
                            <a href="https://goo.gl/maps/umDtPbjVzrkAKitF8" target="_blank">
                                <p class="box-desc-text text-white">
                                    215 Henderson Rd, # 02 07,
                                    Singapore 159554
                                </p>
                            </a>
                        </div>
                        <div class="col-sm-6 text-white mb-3">
                            <h6 class="box-heading-text">{{
                                    this.trans("PAGES.CONTACT.CONTACT_INFORMATION.EMAIL")
                                }}</h6>
                            <a href="mailto:info@casaitaliaglobal.sg">
                                <p class="box-desc-text text-white">
                                    info@casaitaliaglobal.sg
                                </p>
                            </a>
                        </div>
                        <div class="col-sm-6 text-white mb-3">
                            <h6 class="box-heading-text">{{ this.trans("PAGES.CONTACT.CONTACT_INFORMATION.PHONE") }}
                                1</h6>
                            <a href="tel:+6563378809">
                                <p class="box-desc-text text-white">
                                    +65 6337 8809
                                </p>
                            </a>
                        </div>
                        <div class="col-sm-6 text-white mb-3">
                            <h6 class="box-heading-text">{{ this.trans("PAGES.CONTACT.CONTACT_INFORMATION.PHONE") }}
                                2</h6>
                            <a href="https://api.whatsapp.com/send?phone=+6592757505" target="_blank">
                                <p class="box-desc-text text-white">
                                    +65 9275 7505
                                </p>
                            </a>
                        </div>
                        <div class="w-100 d-flex align-items-end" style="border-radius: 12px">
                            <iframe class="map-frame"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.821407636733!2d103.81704341453843!3d1.280851199065791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bd7990f8d67%3A0x136ddc596ee1d67a!2s215%20Henderson%20Rd%2C%20%23%2002%2007%2C%20Singapore%20159554!5e0!3m2!1sen!2smk!4v1646677065802!5m2!1sen!2smk"
                                    style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";

export default {
    name: "Contact",
    data() {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                message: '',
            }
        }
    },
    mounted() {
        this.$root.setTitle(this.trans('NAV_ITEMS.CONTACT'))
        window.scrollTo(0, 0)
        document.body.classList.add('bg-blue')
        document.getElementById('push-button').classList.add('white')
        document.getElementById('nav').classList.add('white')
        document.getElementById('footer').classList.add('white')

        $(".language-selection-box").addClass("white");
        $(".lang-link").removeClass("router-link-active");
        $(".lang-link." + localStorage.selectedLanguage).addClass("router-link-active");
    },
    unmounted() {
        $(".language-selection-box").removeClass("white");
        document.body.classList.remove('bg-blue')
        document.getElementById('push-button').classList.remove('white')
        document.getElementById('nav').classList.remove('white')
        document.getElementById('footer').classList.remove('white')
    },
    methods: {
        reset() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
        postNow() {
            var $this = this
            $("#form-status-message").html(this.trans("PAGES.CONTACT.CONTACT_FORM.SENDING"));
            $("#contact-form .link-button").addClass("disabled");
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.form.name);
            bodyFormData.append('email', this.form.email);
            bodyFormData.append('phone', this.form.phone);
            bodyFormData.append('message', this.form.message);

            window.axios({
                method: "post",
                url: "http://new.casaitaliaglobal.sg/form-submit.php",
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                    .then(function (response) {
                        //handle success
                        if (response.status === 200) {
                            $('#contact-form')[0].reset();
                            $("#form-status-message").html("");
                            setTimeout(function () {
                                $("#contact-form .link-button").removeClass("disabled");
                                $("#form-status-message").html($this.trans("PAGES.CONTACT.CONTACT_FORM.RESPONSES.SUCCESS"));
                            }, 5000);
                        }
                    })
                    .catch(function (response) {
                        //handle error
                        if (response || response.status !== 200) {
                            $("#form-status-message").html($this.trans("PAGES.CONTACT.CONTACT_FORM.RESPONSES.FAILURE"));
                            setTimeout(function () {
                                $("#contact-form .link-button").removeClass("disabled");
                                $("#form-status-message").html("");
                            }, 5000);
                        }
                    });
        }
    }
}
</script>

<style scoped>

</style>